// External
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

// Internal
import { AppsConfig } from './AppsConfigModel.model';
import { ConfigService, DeployType } from './config.service';
import { App } from './ProductsConfig.model';
import { AppLevels } from '../values/subscriptions.values.service';
import { DeviceArchitecture, OperatingSystems, ProductsToInstall, ValuesService } from '../values/values.service';

@Injectable({
    providedIn: 'root'
})

export class AppsConfigService {

    constructor(
        private readonly configService: ConfigService,
        private readonly valuesService: ValuesService,
        private readonly deviceDetectorService: DeviceDetectorService
    ) {
        this.appsConfig = JSON.parse(JSON.stringify(this._appsConfig));
        this.populateProtectionApps();
        this.computeProtectionFlag();
    }

    private readonly appsConfig = {};
    private showInstallButton = false;

    /**
     * Two objects are needed: "_appsConfig" and "appsConfig", type "AppsConfig". This type is defined in "AppsConfigModel.model.ts".
     * The first object contains the original settings,
     * the ones that you can modify by hand and the second object contains the final settings, that are used in the end
     * throughout the app. The second object could be modified by the logic from other parts of the app. Currently, these changes are made inside
     * data resolver.
     * For example, if you do not have parental on an account, the app will not be present in install modal. That is computed
     * at runtime and set inside "appsConfig".
     *
     * Some flags from config service and replaced with flags from this service.
     * For example, if you set on "false" the property "showApp" for parental, vpn, id theft protection,
     * password manager or digital privacy, their pages will be hidden
     * because there is no point in showing them if the app is removed.
     *
     * Every app is inside this object, for production and for beta as well.
     * You can configure an app for production and for beta separately.
     *
     * For every app we could have the following properties:
     * - showApp: true/falsse - shows/eliminates an app from the entire app
     * - showDeployFlow: true/false = enables/disables deploy of an app from the entire app; not written for all apps because not all apps have deploy flows
     * - availableOSes: array of operating systems - you MUST take them from "OperatingSystems" in values. This array is populated with the os-es the the
     *                          app is available for. Some apps have this array empty because for those apps, the available os-es are not used throughout the code.
     *                          For the apps that have this array populated, it is populated with the maximum amount of os-es the app is available for,
     *                          so in _appsConfig you have the starting
     *                          point for those apps and you can disable apps/deploy for apps for all/some os-es.
     *                          If an app is usually available for an os
     *                          and you want the app to stop being available for that os, you just remove that os from the "availableOSes" array.
     *                          OBS.: If you want an app to be available for osx, you
     *                          MUST ADD BOTH "mac" and "osx" to the array. If you want an app to stop being available for osx, you
     *                          MUST REMOVE BOTH "mac" and "osx" from the array
     *
     * "installFlowProtectionApps" is an array that contains all app ids of protection apps that are default for every os
     * (you can find them insude the object this.valuesService.compatibleOsInfo)
     * I iterate over all these apps and determine if at least one of them are allowed to appear ("showApp" property)
     * or at least one is allowed to have deploy flow ("showDeployFlow" property)
     * After I compute these 2 properties I put them inside "appsConfig" object, where I declare a new app that does not exist in reality - "this.valuesService.appProtection"
     * I use this where I want to hide/show deploy flow for protection which is not an app but a category of apps that appear inside the install modal.
     * So in order to hide the protection category from install modal for example, you need to modify all the apps that are under this category
     * (you must look inside "this.valuesService.compatibleOsInfo" and see all the app ids that need to be modified here in order to remove protction category)
     *
     * Also, "this.valuesService.appPassManagerSfr" is another "fake" app that should be used and configured only for sfr.
     *
     */
    private readonly _appsConfig: AppsConfig = {
        [DeployType.PROD]: {
            [this.valuesService.appPassManagerSfr]: {
                id: this.valuesService.appPassManagerSfr,
                showApp: false,
                showDeployFlow: false,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS,
                    OperatingSystems.LINUX
                ]
            },
            [this.valuesService.appProtection]: {
                id: this.valuesService.appProtection,
                showApp: true,
                showDeployFlow: true,
                //> Example of possible change:  availableOSes: [OperatingSystems.MAC, OperatingSystems.OSX]
                availableOSes: []
            },
            // >---- protection apps -----
            [this.valuesService.appCL]: {
                id: this.valuesService.appCL,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [OperatingSystems.WINDOWS]
            },
            [this.valuesService.appAVFM]: {
                id: this.valuesService.appAVFM,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [OperatingSystems.MAC, OperatingSystems.OSX]
            },
            [this.valuesService.appBMS]: {
                id: this.valuesService.appBMS,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [OperatingSystems.ANDROID]
            },
            [this.valuesService.appBIS]: {
                id: this.valuesService.appBIS,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [OperatingSystems.IOS]
            },
            [this.valuesService.appBIP]: {
                id: this.valuesService.appBIP,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [OperatingSystems.IOS]
            },
            [this.valuesService.appAVFREE]: {
                id: this.valuesService.appAVFREE,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [OperatingSystems.WINDOWS]
            },
            // >----------------------
            [this.valuesService.appVPN]: {
                id: this.valuesService.appVPN,
                showApp: false,
                showDeployFlow: false,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS
                ],
                unsupportedArch: {
                    [OperatingSystems.WINDOWS]: [DeviceArchitecture.ARM64]
                }
            },
            [this.valuesService.appPA]: {
                id: this.valuesService.appPA,
                showApp: false,
                showDeployFlow: false,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS
                ],
                unsupportedArch: {
                    [OperatingSystems.WINDOWS]: [DeviceArchitecture.ARM64]
                }
            },
            [this.valuesService.appPANCC]: {
                id: this.valuesService.appPANCC,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS
                ],
                unsupportedArch: {
                    [OperatingSystems.WINDOWS]: [DeviceArchitecture.ARM64]
                }
            },
            [this.valuesService.appDIP]: {
                id: this.valuesService.appDIP,
                showApp: false,
                showDeployFlow: false,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS,
                    OperatingSystems.LINUX
                ]
            },
            [this.valuesService.appPassManager]: {
                id: this.valuesService.appPassManager,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS,
                    OperatingSystems.LINUX
                ]
            },
            [this.valuesService.appIdTheft]: {
                id: this.valuesService.appIdTheft,
                showApp: false,
                showDeployFlow: false,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS,
                    OperatingSystems.LINUX
                ]
            },
            [this.valuesService.appWebmailProtection]: {
                id: this.valuesService.appWebmailProtection,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appOptimizeDvm]: {
                id: this.valuesService.appOptimizeDvm,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appDvm]: {
                id: this.valuesService.appDvm,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appBOX2]: {
                id: this.valuesService.appBOX2,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appBOX2B]: {
                id: this.valuesService.appBOX2B,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appBOXMGMT]: {
                id: this.valuesService.appBOXMGMT,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appBOX1]: {
                id: this.valuesService.appBOX1,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appBOXLIGHT]: {
                id: this.valuesService.appBOXLIGHT,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appCM]: {
                id: this.valuesService.appCM,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appHVA]: {
                id: this.valuesService.appHVA,
                showApp: true,
                showDeployFlow: false,
                availableOSes: [OperatingSystems.WINDOWS]
            },
            [this.valuesService.appSFP]: {
                id: this.valuesService.appSFP,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appSFM]: {
                id: this.valuesService.appSFM,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appSFMP]: {
                id: this.valuesService.appSFMP,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appVR]: {
                id: this.valuesService.appVR,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appSR]: {
                id: this.valuesService.appSR,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appPO]: {
                id: this.valuesService.appPO,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appAR]: {
                id: this.valuesService.appAR,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appCT]: {
                id: this.valuesService.appCT,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            }
        },
        [DeployType.BETA]: {
            [this.valuesService.appPassManagerSfr]: {
                id: this.valuesService.appPassManagerSfr,
                showApp: false,
                showDeployFlow: false,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS,
                    OperatingSystems.LINUX
                ]
            },
            [this.valuesService.appProtection]: {
                id: this.valuesService.appProtection,
                showApp: true,
                showDeployFlow: true,
                //> Example of possible change:  availableOSes: [OperatingSystems.MAC, OperatingSystems.OSX]
                availableOSes: []
            },
            // >---- protection apps -----
            [this.valuesService.appCL]: {
                id: this.valuesService.appCL,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [OperatingSystems.WINDOWS]
            },
            [this.valuesService.appAVFM]: {
                id: this.valuesService.appAVFM,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [OperatingSystems.MAC, OperatingSystems.OSX]
            },
            [this.valuesService.appBMS]: {
                id: this.valuesService.appBMS,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [OperatingSystems.ANDROID]
            },
            [this.valuesService.appBIS]: {
                id: this.valuesService.appBIS,
                showApp: false,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appBIP]: {
                id: this.valuesService.appBIP,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [OperatingSystems.IOS]
            },
            [this.valuesService.appAVFREE]: {
                id: this.valuesService.appAVFREE,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [OperatingSystems.WINDOWS]
            },
            // >----------------------
            [this.valuesService.appVPN]: {
                id: this.valuesService.appVPN,
                showApp: false,
                showDeployFlow: false,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS
                ],
                unsupportedArch: {
                    [OperatingSystems.WINDOWS]: [DeviceArchitecture.ARM64]
                }
            },
            [this.valuesService.appPA]: {
                id: this.valuesService.appPA,
                showApp: false,
                showDeployFlow: false,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS
                ],
                unsupportedArch: {
                    [OperatingSystems.WINDOWS]: [DeviceArchitecture.ARM64]
                }
            },
            [this.valuesService.appPANCC]: {
                id: this.valuesService.appPANCC,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS
                ],
                unsupportedArch: {
                    [OperatingSystems.WINDOWS]: [DeviceArchitecture.ARM64]
                }
            },
            [this.valuesService.appDIP]: {
                id: this.valuesService.appDIP,
                showApp: false,
                showDeployFlow: false,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS,
                    OperatingSystems.LINUX
                ]
            },
            [this.valuesService.appPassManager]: {
                id: this.valuesService.appPassManager,
                showApp: true,
                showDeployFlow: true,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS,
                    OperatingSystems.LINUX
                ]
            },
            [this.valuesService.appIdTheft]: {
                id: this.valuesService.appIdTheft,
                showApp: false,
                showDeployFlow: false,
                availableOSes: [
                    OperatingSystems.WINDOWS,
                    OperatingSystems.MAC,
                    OperatingSystems.OSX,
                    OperatingSystems.ANDROID,
                    OperatingSystems.IOS,
                    OperatingSystems.LINUX
                ]
            },
            [this.valuesService.appWebmailProtection]: {
                id: this.valuesService.appWebmailProtection,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appOptimizeDvm]: {
                id: this.valuesService.appOptimizeDvm,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appDvm]: {
                id: this.valuesService.appDvm,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appBOX2]: {
                id: this.valuesService.appBOX2,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appBOX2B]: {
                id: this.valuesService.appBOX2B,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appBOXMGMT]: {
                id: this.valuesService.appBOXMGMT,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appBOX1]: {
                id: this.valuesService.appBOX1,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appBOXLIGHT]: {
                id: this.valuesService.appBOXLIGHT,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appCM]: {
                id: this.valuesService.appCM,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appHVA]: {
                id: this.valuesService.appHVA,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appSFP]: {
                id: this.valuesService.appSFP,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appSFM]: {
                id: this.valuesService.appSFM,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appSFMP]: {
                id: this.valuesService.appSFMP,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appVR]: {
                id: this.valuesService.appVR,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appSR]: {
                id: this.valuesService.appSR,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appPO]: {
                id: this.valuesService.appPO,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appAR]: {
                id: this.valuesService.appAR,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            },
            [this.valuesService.appCT]: {
                id: this.valuesService.appCT,
                showApp: true,
                showDeployFlow: false,
                availableOSes: []
            }
        }
    };

    /**
     * For iosprotection there is no implementation in connect so we send iossecurity instead
     */
    private readonly osAppIdCorrespondence = {
        [OperatingSystems.WINDOWS]: this.valuesService.appCL,
        [OperatingSystems.MAC]: this.valuesService.appAVFM,
        [OperatingSystems.OSX]: this.valuesService.appAVFM,
        [OperatingSystems.ANDROID]: this.valuesService.appBMS,
        [OperatingSystems.IOS]: this.valuesService.appBIS
    };

    private readonly _downloadSppsCategories = {
        [ProductsToInstall.PROTECTION] : this.valuesService.appProtection,
        [ProductsToInstall.PARENTAL] : this.valuesService.appPA,
        [ProductsToInstall.PARENTALNCC] : this.valuesService.appPANCC,
        [ProductsToInstall.VPN] : this.valuesService.appVPN,
        [ProductsToInstall.PASSWORDMANAGER] : this.valuesService.appPassManager
    };

    /**
     * After "populateProtectionApps()" is ran inside the constructor, this array will be populated like this:
     * "installFlowProtectionApp = [valuesService.appCL , valuesService.appAVFM, valuesService.appBMS, valuesService.appBIS];"
     * These are all the default protection apps for every os that you can install protection on.
     * Thes apps are taken dynamically from "valuesService.compatibleOsInfo" just in case these apps change
     */
    installFlowProtectionApps = [];

    private readonly totalSecurityApps = new Set([this.valuesService.appCL, this.valuesService.appAVFM, this.valuesService.appBMS, this.valuesService.appBIP]);

    /**
     * Gets the total security app for the install modal that is compatible with the current os.
     * Total security for all platforms is an app that has all the protection apps in one.
     * @returns {string} - the app id of the total security app that is compatible with the current os
     */
    public getTotalSecurityAppBaseOnOs(): string {
        const deviceOS = this.deviceDetectorService.os.toLocaleLowerCase();
        return this.osAppIdCorrespondence[deviceOS] ?? this.valuesService.appCL;
    }

    showAppOriginal(appId: string): boolean {
        const deployType = this.configService.getDeployType();
        return !!this._appsConfig?.[deployType]?.[appId]?.showApp;
    }

    showApp(appId: string) {
        const deployType = this.configService.getDeployType();
        return !!this.appsConfig?.[deployType]?.[appId]?.showApp;
    }

    setShowApp(appId: string, showApp: boolean) {
        const deployType = this.configService.getDeployType();
        this.appsConfig[deployType][appId].showApp = showApp;
    }

    showDeployFlow(appId: string): boolean {
        const deployType = this.configService.getDeployType();
        return !!(this.appsConfig?.[deployType]?.[appId]?.showApp
                && this.appsConfig?.[deployType]?.[appId]?.showDeployFlow);
    }

    showAppDeployFlowForOs(appId: string, os): boolean {
        const deployType = this.configService.getDeployType();
        return !!(this.appsConfig?.[deployType]?.[appId]?.showApp
                && this.appsConfig?.[deployType]?.[appId]?.showDeployFlow
                && this.appsConfig?.[deployType]?.[appId]?.availableOSes?.indexOf(os) !== -1);
    }

    showAppForOs(appId: string, os) {
        const deployType = this.configService.getDeployType();
        return !!(this.appsConfig?.[deployType]?.[appId]?.showApp
                && this.appsConfig?.[deployType]?.[appId]?.availableOSes?.indexOf(os) !== -1);
    }

    /**
     * Checks if deploy flow for a product is available for the specified os and architecture
     * @param {string} appId App id
     * @param {string} os Device operating system
     * @param {string} arch Device architecture
     * @returns {boolean} True if deploy flow is available for the specified os and architecture
     */
    public showAppDeployFlowForArchitecture(appId: string, os: string, arch: string): boolean {
        const deployType = this.configService.getDeployType();
        const unsupportedArch = this.appsConfig?.[deployType]?.[appId]?.unsupportedArch ?? {};
        const unsupportedArchForOs = unsupportedArch[os] ?? [];
        return !!(this.appsConfig?.[deployType]?.[appId]?.showApp
                && this.appsConfig?.[deployType]?.[appId]?.showDeployFlow
                && !(unsupportedArchForOs.indexOf(arch) !== -1));
    }

    /**
     * Checks if devices with os operating system and arch architecture are compatible with the given app id
     * @param {string} appId App id
    * @param {string} os Device operating system
     * @param {string} arch Device architecture
     * @returns {boolean} True if app is available for the specified os and architecture
     */
    public showAppForArchitecture(appId: string, os: string, arch: string): boolean {
        const deployType = this.configService.getDeployType();
        const unsupportedArch = this.appsConfig?.[deployType]?.[appId]?.unsupportedArch ?? {};
        const unsupportedArchForOs = unsupportedArch[os] ?? [];
        return !!(this.appsConfig?.[deployType]?.[appId]?.showApp
                && !(unsupportedArchForOs.indexOf(arch) !== -1));
    }

    /**
     * Gets the array with all the available oses for the given app id
     * @param appId The app id
     * @returns {string[]} The array with all the available oses for the given app id
     */
    public getAvailableOses(appId: string): OperatingSystems[] {
        const deployType = this.configService.getDeployType();
        return this.appsConfig?.[deployType]?.[appId]?.availableOSes ?? [];
    }

    /**
     * Tells if an app cn be a part of the total security for all platforms app in the install modal
     * @param app The app to check
     * @returns {boolean} True if the app can be a part of the total security for all platforms app in the install modal, false otherwise
     */
    public isContainedInInstallModalTotalSecurityApp(app: App): boolean {
        return this.totalSecurityApps.has(app.app_id);
    }

    /**
     * Gets the number of apps that are a part of the total security for all platforms app in the install modal
     * @returns {number} The number of apps that are a part of the total security for all platforms app in the install modal
     */
    public getNumberOfAppsForTotalSecurity(): number {
        return this.totalSecurityApps.size;
    }

    /**
     * Gets the array with all the app ids that are a part of the total security for all platforms app in the install modal
     * @returns {string[]} The array with all the app ids that are a part of the total security for all platforms app in the install modal
     */
    public getInstallModalTotalSecurityApps(): string[] {
        return Array.from(this.totalSecurityApps);
    }

    /**
     * Puts all default protection app ids for all os-es(from "valuesService.compatibleOsInfo") inside the array "installFlowProtectionApps"
     */
    populateProtectionApps() {
        const protectionAppsSet = new Set();
        for (const os in this.valuesService.compatibleOsInfo) {
            protectionAppsSet.add(this.valuesService.compatibleOsInfo[os].appId);
        }
        this.installFlowProtectionApps = Array.from(protectionAppsSet);
    }

    /**
     * Iterates through all the protection apps from this.installFlowProtectionApps (this.valuesService.compatibleOsInfo) and
     * for all the apps that have the deploy flow available, gathers all the os-es and puts them in "availableOSes" for "valuesService.appProtection"
     * (this app is not a real app, it's a fake app used to add an entry on "_appsConfig" and "appsConfig" mainly used to configure deploy flow for protection).
     * If no app has their deploy flow available, then "valuesService.appProtection" will have "showDeployFlow" set on "false".
     * Also, if no app is available for any of the possible os-es, "showApp" and "showDeployFlow" are set on false. Otherwise, these
     * settings have their initial values, the ones written inside "_appsConfig" for "valuesService.appProtection".
     * These check are made on "_appsConfig" because the settings for the protection apps do not change individually at runtime.
     */
    computeProtectionFlag() {
        const deployType = this.configService.getDeployType();
        const osSet = new Set();
        let showDeployFlowProtection = false;
        for (const app of this.installFlowProtectionApps) {
            if (!this.showApp(app)) {
                continue;
            }

            const osForApp:Array<OperatingSystems> = this._appsConfig[deployType][app]?.availableOSes ? this._appsConfig[deployType][app]?.availableOSes : [];
            for (const os of osForApp) {
                if (this.showDeployFlow(app)) {
                    showDeployFlowProtection = true;
                }
                osSet.add(os);
            }
        }

        this.appsConfig[deployType][this.valuesService.appProtection].showDeployFlow = showDeployFlowProtection;

        if (osSet.size === 0) {
            this.appsConfig[deployType][this.valuesService.appProtection].showApp = false;
            this.appsConfig[deployType][this.valuesService.appProtection].showDeployFlow = false;
        }
        this.appsConfig[deployType][this.valuesService.appProtection].availableOSes = Array.from(osSet);
    }

    /**
     * This checks if deploy flow for a product is available in download page.
     * @param product Product from download page
     * @returns boolean - If deploy flow for the product is available
     */
    showDownloadedProductDeployFlow(product): boolean {
        const appId = this._downloadSppsCategories[product];
        const deployType = this.configService.getDeployType();
        return !!(this.appsConfig?.[deployType]?.[appId]?.showApp
                && this.appsConfig?.[deployType]?.[appId]?.showDeployFlow);
    }

    /**
     * This checks if deploy flow for a product is available for the sepcified in download page.
     * @param product Product from download page
     * @param os
     * @returns boolean - If deploy flow for the product is available for os
     */
    showDownloadedProductDeployFlowForOs(product, os): boolean {
        const appId = this._downloadSppsCategories[product];
        const deployType = this.configService.getDeployType();
        return !!(this.appsConfig?.[deployType]?.[appId]?.showApp
                && this.appsConfig?.[deployType]?.[appId]?.showDeployFlow
                && this.appsConfig?.[deployType]?.[appId]?.availableOSes?.indexOf(os) !== -1);
    }

    /**
     * This checks if deploy flow for a product is available for the specified os and architecture in download page.
     * @param product Product from download page
     * @param {string} os Device operating system
     * @param {string} arch Device architecture
     * @returns {boolean} True if deploy flow for the product is available for os and architecture in download page
     */
    public showDownloadedProductDeployFlowForArch(product, os: string, arch: string): boolean {
        const appId = this._downloadSppsCategories[product];
        const deployType = this.configService.getDeployType();
        const unsupportedArch = this.appsConfig?.[deployType]?.[appId]?.unsupportedArch ?? {};
        const unsupportedArchForOs = unsupportedArch[os] ?? [];
        return !!(this.appsConfig?.[deployType]?.[appId]?.showApp
                && this.appsConfig?.[deployType]?.[appId]?.showDeployFlow
                && !(unsupportedArchForOs.indexOf(arch) !== -1));
    }

    showInstallButtonForAllApps() {
        this.showInstallButton = this.showDeployFlow(this.valuesService.appProtection)
                                || this.showDeployFlow(this.valuesService.appVPN)
                                || this.showDeployFlow(this.valuesService.appPA)
                                || this.showDeployFlow(this.valuesService.appPANCC)
                                || this.showDeployFlow(this.valuesService.appPassManager);
    }

    showInstallAppsForDevice() {
        return this.showApp(this.valuesService.appProtection)
                || this.showApp(this.valuesService.appVPN)
                || this.showApp(this.valuesService.appPA)
                || this.showApp(this.valuesService.appPANCC);
    }

    getShowInstallButtonForAllApps(): boolean {
        return this.showInstallButton;
    }

    showDployFlowForBundle(bundle): boolean {
        const apps = bundle?.applications;
        if (apps) {
            for (const app of apps) {
                if (this.showDeployFlow(app.app_id)) {
                    return true;
                }
            }
        }
        return false;
    }

    showTsDeployFlow(): boolean {
        const appIdsForTs = [
            this.valuesService.appCL,
            this.valuesService.appPA,
            this.valuesService.appBMS,
            this.valuesService.appAVFM,
            this.valuesService.appBIP,
            this.valuesService.appVPN
        ];

        for (const appId of appIdsForTs) {
            if (this.showDeployFlow(appId)) {
                return true;
            }
        }
        return false;
    }

    showPsDeployFlow(): boolean {
        return this.showTsDeployFlow();
    }

}
